import React from "react";
import logo from "../images/logo_jp.png";

export function Footer() {
  return (
    <div className="h-40 flex mb-6 flex-col safe-bottom max-w-lg mx-auto justify-center items-center sm:flex-row sm:items-end">
      <img src={logo} alt=""/>
      <div>
        <p className="text-base-light">〒877-0071</p>
        <p className="text-base-light">大分県日田市玉川3丁目1511-1</p>
      </div>
    </div>
  )
}