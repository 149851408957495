import React from "react";

type Props = {
  title: string
}

export function HomeSectionHeader(props: Props) {
  return (
    <div className="sm:w-8/12 mx-auto p-2">
      <h1 className="text-base-light text-3xl font-semibold">{props.title}</h1>
    </div>
  )
}