import React from "react";
import {Fade} from "./animations/fade";
import companyMessage from "../images/company_message.png";

export function CompanyMessage() {
  return (
    <div className="flex h-screen justify-center items-center">
      <Fade>
        <img className="max-w-sm w-8/12 sm:max-w-lg" src={companyMessage} alt=""/>
      </Fade>
    </div>
  )
}