import React from "react";
import smallLogo from "../images/logo.png";
import {Fade} from "./animations/fade";

export function SmallLogo() {
  return (
    <Fade>
      <div className="fixed right-4 top-4 safe-bottom w-32 md:w-40">
        <img src={smallLogo} alt=""/>
      </div>
    </Fade>
  )
}