import React from "react";
import {InfoType} from "../../models/infoType";
import {InvalidEnumError} from "../../models/invalidEnumError";

export type InfoItemProps = {
  title: string;
  value: string;
  type?: InfoType;
}

export function InfoItem(props: InfoItemProps) {
  let elem = <></>

  switch (props.type) {
    case "email":
      elem = <a href={"mailto:" + props.value}>{props.value}</a>;
      break;
    case "phoneNumber":
      elem = <a href={"tel:" + props.value}>{props.value}</a>;
      break;
    case "default":
    case undefined:
      elem = <p>{props.value}</p>;
      break;
    default:
      throw new InvalidEnumError(props.type);
  }

  return (
    <div className="w-screen sm:w-8/12 sm:ml-auto p-2 flex flex-col justify-center items-start">
      <div className="text-base-light text-sm sm:text-lg font-semibold pl-4 sm:pl-0">{props.title}</div>
      <div className="text-base-light text-sm sm:text-xl font-bold mt-1 pl-12 sm:pl-20">{elem}</div>
    </div>
  )
}