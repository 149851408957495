import React from 'react';
import {BigLogo} from "./components/bigLogo";
import {SmallLogo} from "./components/smallLogo";
import {CompanyMessage} from "./components/companyMessage";
import {CompanyInfo} from "./components/companyInfoTable";
import {HomeSectionHeader} from "./components/feature/homeSectionHeader";
import {Gallery} from "./components/gallery";
import {Footer} from "./components/footer";

function App() {
  return (
    <div className="app-bg w-screen safe-bottom">
      <BigLogo />
      <CompanyMessage />
      <HomeSectionHeader title="会社情報" />
      <CompanyInfo />
      <HomeSectionHeader title="作品" />
      <Gallery />
      <Footer />
      <SmallLogo />
    </div>
  );
}

export default App;
