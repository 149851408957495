import React from "react";

type Props = {
  imgSrc: string | null;
}

export function GalleryItem(props: Props) {
  const emptyMessage = (
    <div className="h-full bg-gray-900 flex justify-center items-center">
      <p className="text-gray-500 font-bold">随時追加予定</p>
    </div>
  )

  return (
    <div className="rounded-xl overflow-hidden shadow-lg">
      {props.imgSrc !== null ? (
        <img className="object-cover" src={props.imgSrc} alt=""/>
      ) : emptyMessage}
    </div>
  )
}