import React from "react";
import gallery1 from "../images/gallery1.jpg";
import {GalleryItem} from "./mol/galleryItem";
import {Fade} from "./animations/fade";

export function Gallery() {
  return (
    <Fade>
      <div className="w-screen sm:w-8/12 sm:mx-auto grid grid-cols-2 sm:grid-cols-3 gap-4 mb-20">
        <GalleryItem imgSrc={gallery1}/>
        <GalleryItem imgSrc={null}/>
      </div>
    </Fade>
  )
}