import React from "react";
import {InfoItem, InfoItemProps} from "./mol/infoItem";
import {Fade} from "./animations/fade";

export function CompanyInfo() {
  const info: InfoItemProps[] = [
    {title: "電話番号", value: "0973-22-7332", type: "phoneNumber"},
    {title: "FAX", value: "0973-22-0058"},
    {title: "メールアドレス", value: "nito-uto@abelia.ocn.ne.jp", type: "email"},
    {title: "会社設立", value: "1962年"},
    {title: "代表者名", value: "代表取締役社長 河津啓治"},
    {title: "住所", value: "〒877-0071 大分県日田市玉川3丁目1511-1"},
  ]

  return (
    <Fade>
      <div className="flex flex-col justify-center items-center">
        {info.map(inf => (
          <InfoItem key={inf.title} title={inf.title} value={inf.value} type={inf.type}/>
        ))}
        <iframe
          className="w-screen sm:w-8/12 h-96 rounded-xl mt-2 mb-16"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.7269379719273!2d130.92171831597318!3d33.32595356309825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35416a33d758b19f%3A0xc4921751dad1c8b3!2z5pel5p2x5pyo5bel77yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1571755674055!5m2!1sja!2sjp"
          title="company_address"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </Fade>
  )
}