import React from "react";
import bigLogo from "../images/logo_en.png";
import {Fade} from "./animations/fade";

export function BigLogo() {
  return (
    <div className="h-screen flex justify-center items-center">
      <Fade>
        <img className="max-w-sm w-10/12 md:max-w-lg pb-16" src={bigLogo} alt=""/>
      </Fade>
    </div>
  )
}